import React from 'react';
import { TextInput } from 'shared-library';
import { Column, Row } from '../Layout';
import { ReferenceIdTextInput } from './ReferenceIdTextInput';
import { AmountToPay } from '../AmountToPay';
import { StyledSelectInput } from './PaymentTextInputs';

const ChequeColumns = ({ transaction, setTransaction }) => {
  return (
    <>
      <Row>
        <AmountToPay transaction={transaction} setTransaction={setTransaction} />
        <ReferenceIdTextInput transaction={transaction} setTransaction={setTransaction} />
      </Row>
      <Row>
        <Column>
          <StyledSelectInput
            label="ID Verified"
            options={[
              { label: 'yes', value: 'yes' },
              { label: 'no', value: 'no' },
            ]}
            onChange={(option) => setTransaction({ ...transaction, idVerified: option.value })}
          />
        </Column>
        <Column>
          <TextInput
            id="transaction.idType"
            label="ID Type"
            onChange={(value) => setTransaction({ ...transaction, idType: value })}
          />
        </Column>
        <Column>
          <TextInput
            id="transaction.idNum"
            label="ID #"
            onChange={(value) => setTransaction({ ...transaction, idNum: value })}
          />
        </Column>
      </Row>
    </>
  );
};

export default ChequeColumns;
